@import "../styles/colors";
@import "../styles/grid";

.authorized {
    .main {
        margin-top: $navbar-height;
    }
}

.header {
    font-weight: 500;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 20;
    color: $header-text-color;
    background-color: $header-background-color;
    // font-size: 10px;
    // @media (min-width: $grid-breakpoints-xxl) {
    //     font-size: 12px;
    // }

    .container-fluid {
        height: 100%;
        max-height: 100%;
        max-width: $grid-max-width;
        margin: 0 auto;

        .navbar {
            padding: 0;

            .navbar-collapse {
                overflow: auto;
                max-height: calc(100vh - 83px);

                &.show {
                    padding-bottom: 48px;
                }

                @media (min-width: $grid-breakpoints-lg) {
                    overflow: unset;
                    height: $navbar-height;

                    &.show {
                        padding-bottom: initial;
                    }
                }
            }

            .navbar-nav {
                font-size: 30px;
                font-weight: 300;
                text-align: center;

                .nav-link {
                    text-decoration: none;
                    font-weight: 500;

                    svg {
                        display: none;
                    }
                }

                .nav-link {
                    @media (min-width: $grid-breakpoints-lg) {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 10px;

                        padding: 0;
                        margin: 0 15px;
                        // height: 100%;
                        // line-height: 76px;
                        text-decoration: none;
                        text-transform: uppercase;
                        border-top: none;
                        border-bottom: 6px solid transparent;
                        white-space: nowrap;
                        color: $main-text-color;

                        @media (min-width: $grid-breakpoints-xl) {
                            font-size: 12px;
                        }

                        &.active,
                        &:hover {
                            color: $header-text-color;
                            // border-bottom: 6px solid $header-text-color;
                        }

                        svg {
                            display: initial;
                        }
                    }
                }
            }

            @media (min-width: $grid-breakpoints-lg) {
                .categories {
                    height: $navbar-height;

                    &.navbar-nav {
                        height: 100%;

                        .nav-link {
                            &.active,
                            &:hover {
                                color: $header-text-color;
                                border-bottom: 6px solid $header-text-color;
                            }
                        }
                    }
                }
            }
        }

        .navbar-brand {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            height: $navbar-height;

            svg {
                width: 158px;
            }

            @media (min-width: $grid-breakpoints-lg) {
                svg {
                    width: 148px;
                }
            }

            @media (min-width: $grid-breakpoints-xl) {
                svg {
                    width: 158px;
                }
            }
        }

        .navbar-toggler {
            border: none;
            outline: none;
            box-shadow: none;
            color: $blue-color;

            .svg-inline--fa {
                height: 33px;
                &.fa-w-6 {
                    width: initial;
                }
            }

            &:hover,
            &:active {
                outline: none;
                box-shadow: none;
            }
        }
    }
}
