@import "../../styles/colors";
@import "../../styles/grid";

.header {
    .categories {
        flex-grow: 1;
        justify-content: center;

        .navbar-nav {
            .nav-link {
                @media (min-width: $grid-breakpoints-lg) {
                    &.active,
                    &:hover {
                        color: $header-text-color;
                        border-bottom: 6px solid $header-text-color;
                    }
                }
            }
        }

        .dropdown {
            .dropdown-toggle {
                height: 100%;
                @media (min-width: $grid-breakpoints-lg) {
                    &.active,
                    &:hover {
                        border-bottom: 6px solid transparent !important;
                    }
                }
            }
            .dropdown-menu {
                border: none;

                .dropdown-item {
                    text-align: center;
                    text-decoration: none;
                    color: $gray-color;
                    font-weight: bold;

                    &.active {
                        color: white;
                    }
                }
                .dropdown-header {
                    font-weight: normal;
                }
            }

            @media (min-width: $grid-breakpoints-lg) {
                padding: 5px 0;

                &.show {
                    background-color: $dropdown-background-color;
                    border: none;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }

                .dropdown-menu {
                    background-color: $dropdown-background-color;
                    border: none;
                    border-radius: 20px;
                    border-top-right-radius: 0;
                    margin-top: -2px;
                    transform: translate(-10px, 0);

                    .dropdown-item {
                        text-align: left;
                        font-size: 12px;
                        line-height: 29px;
                        padding: 0 20px;
                        font-weight: bold;
                        text-decoration: none;
                        color: $dropdown-text-color;
                    }
                    a.dropdown-item {
                        &.active,
                        &:hover {
                            color: white !important;
                            background-color: $dropdown-selected-color !important;
                        }
                    }

                    .dropdown-header {
                        font-weight: normal;
                        color: $dropdown-header-color;
                        cursor: default;

                        &:hover {
                            color: $dropdown-header-color;
                            background-color: inherit;
                        }
                    }
                }
            }

            @media (min-width: $grid-breakpoints-lg) {
                .dropdown-menu {
                    transform: translate(-62px, 0);
                }
            }

            @media (min-width: $grid-breakpoints-xl) {
                .dropdown-menu {
                    transform: translate(-50px, 0);
                }
            }
        }
    }
}
