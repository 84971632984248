@import "../styles/colors";
@import "../styles/grid";

#root,
.page {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    padding: 0;

    .main {
        flex-grow: 1;

        .container-fluid {
            padding: 0;
            padding-bottom: 1rem;
            background-color: $main-background-color;

            & > .row,
            & > .form-row {
                max-width: $grid-max-width;
                margin: 0 auto;
            }
        }
    }
}
