// $blue-color: #4a98b8;
// $blue-dark-color: #336b82; /* hover */
// $gray-light-color: #f2f2f2;
// $gray-lighten-color: #d8d8d8;
// $gray-color: #9b9b9b;
// $gray-dark-color: #464646;
// $blue2-color: #3092c0;
// $blue3-color: #2b82ac;

$blue-color: #326292;
$blue2-color: #316294;
$blue-light-color: #76a6d6;
$gray-color: #5a6a6b;
$gray2-color: #586b76;
$gray3-color: #697a84;
$gray-light-color: #e6e6e6;
$white-darken-color: #f5f5f5;
$white2-darken-color: #dddddd;
$white3-darken-color: #c1c1c1;

$primary-color: #326195;
$primary-hover-color: #3f71a8;
$primary-disabled-color: #98afca;
$secondary-color: #0092b4;
$secondary-hover-color: #1cabcc;
$secondary-disabled-color: #72c8d9;
$success-color: #05c654;
$success-hover-color: #29e375;
$success-disabled-color: #81e2a9;
$warning-color: #ffa700;
$warning-hover-color: #fcb42c;
$warning-disabled-color: #ffd37f;
$danger-color: #ce1d1d;
$danger-hover-color: #ce1d1d;
$danger-disabled-color: #e68d8d;
$info-color: #5a6a6b;
$info-hover-color: #6c7070;
$info-disabled-color: lighten(#5a6a6b, 35%);

$danger-alt-color: #fc4747;
$login-background-color: #586b76;

$header-text-color: $blue-color;
$header-background-color: white;

$main-text-color: $gray-color;
$main-background-color: white;

$footer-background-color: $gray2-color;
$footer-content-background-color: $gray3-color;
$footer-text-color: white;

$link-color: $blue-color;
$link-hover-color: $blue-light-color;

$dropdown-background-color: $white-darken-color;
$dropdown-selected-color: $blue-color;
$dropdown-text-color: $gray-color;
$dropdown-header-color: $gray-color;
