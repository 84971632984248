@import "../../styles/colors";
@import "../../styles/grid";

.header {
    .profile {
        .dropdown {
            .dropdown-menu {
                border: none;

                .dropdown-item {
                    text-align: center;
                    text-decoration: none;
                    color: $gray-color;
                    font-weight: bold;

                    &.active {
                        color: white;
                    }
                }
                .dropdown-header {
                    font-weight: normal;
                }
            }

            @media (min-width: $grid-breakpoints-lg) {
                padding: 5px 0;

                &.show {
                    background-color: $dropdown-background-color;
                    border: none;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }

                .dropdown-toggle {
                    svg {
                        margin-right: 5px;
                    }
                }

                .dropdown-menu {
                    background-color: $dropdown-background-color;
                    border: none;
                    border-radius: 20px;
                    border-top-right-radius: 0;
                    margin-top: -2px;
                    transform: translate(-141px, 0);

                    .dropdown-item {
                        text-align: left;
                        font-size: 12px;
                        line-height: 29px;
                        padding: 0 20px;
                        font-weight: bold;
                        text-decoration: none;
                        color: $dropdown-text-color;

                        &.active,
                        &:hover {
                            color: white;
                            background-color: $dropdown-selected-color;
                        }
                    }

                    .dropdown-header {
                        font-weight: normal;
                        color: $dropdown-header-color;
                        cursor: default;

                        &:hover {
                            color: $dropdown-header-color;
                            background-color: inherit;
                        }
                    }

                    .dropdown-divider {
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
            }

            @media (min-width: $grid-breakpoints-lg) {
                .dropdown-menu {
                    transform: translate(-120px, 0);
                }
            }

            @media (min-width: $grid-breakpoints-xl) {
                .dropdown-menu {
                    transform: translate(-112px, 0);
                }
            }
        }
    }
}
