@import "styles/custom";
@import "styles/fonts";
@import "styles/colors";
@import "styles/grid";
@import "styles/tables";

.authorized {
    a {
        color: $link-color;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
            color: $link-hover-color;
        }

        &.btn {
            text-decoration: none;
        }
    }

    /* Tytułowy nagłówek */
    h1 {
        font-size: 46px;
        line-height: 66px;
        font-weight: 300;
    }
    @media (min-width: $grid-breakpoints-md) {
        h1 {
            font-size: 50px;
            line-height: 70px;
            font-weight: 300;
        }
    }
    /* Tytułowy nagłówek zmniejszony */
    h2 {
        font-size: 30px;
        line-height: 45px;
        font-weight: normal;
    }
    /* Wyboldowane bodycopy */
    h3 {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
    }
    /* Bodycopy */
    h4 {
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }
    /* Przypis */
    h5 {
        font-size: 12px;
        line-height: 18px;
        font-weight: normal;
    }
    /* Inny */
    h6 {
        font-size: 10px;
        line-height: 16px;
        font-weight: normal;
    }

    .btn {
        font-weight: bold;
        text-transform: uppercase;
        border: none;
        border-radius: 0;
        font-size: 20px;
        white-space: nowrap;
        padding: 10px 30px;

        &.disabled,
        &:disabled {
            opacity: 1;
        }

        &.btn-primary {
            color: white;
            background-color: $primary-color;

            &:hover:not(.disabled):not(:disabled),
            &:active {
                background-color: $primary-hover-color;
            }
            &.disabled,
            &:disabled {
                background-color: $primary-disabled-color;
            }
        }

        &.btn-secondary {
            color: white;
            background-color: $secondary-color;

            &:hover:not(.disabled):not(:disabled),
            &:active {
                background-color: $secondary-hover-color;
            }
            &.disabled,
            &:disabled {
                background-color: $secondary-disabled-color;
            }
        }

        &.btn-success {
            color: white;
            background-color: $success-color;

            &:hover:not(.disabled):not(:disabled),
            &:active {
                background-color: $success-hover-color;
            }
            &.disabled,
            &:disabled {
                background-color: $success-disabled-color;
            }
        }

        &.btn-danger {
            color: white;
            background-color: $danger-color;

            &:hover:not(.disabled):not(:disabled),
            &:active {
                background-color: $danger-hover-color;
            }
            &.disabled,
            &:disabled {
                background-color: $danger-disabled-color;
            }
        }

        &.btn-warning {
            color: white;
            background-color: $warning-color;

            &:hover:not(.disabled):not(:disabled),
            &:active {
                background-color: $warning-hover-color;
            }
            &.disabled,
            &:disabled {
                background-color: $warning-disabled-color;
            }
        }

        &.btn-info {
            color: white;
            background-color: $info-color;

            &:hover:not(.disabled):not(:disabled),
            &:active {
                background-color: $info-hover-color;
            }
            &.disabled,
            &:disabled {
                background-color: $info-disabled-color;
            }
        }

        &.btn-outline-primary {
            font-weight: bold;
            &:hover {
                background-color: inherit;
                color: $link-hover-color;
            }
        }

        .spinner-border {
            vertical-align: baseline;
        }
    }

    input[type="password"].form-control,
    input[type="text"].form-control {
        &:invalid,
        &.is-invalid {
            border-color: $danger-color;
        }
    }

    input[type="radio"].form-check-input {
        color: $gray-color;
    }

    input[type="number"].form-control {
        // text-align: right;
        padding-right: 0.37rem !important; // fixes firefox issue with margins
    }

    a {
        color: $info-color;
        text-decoration: none;
        font-weight: bold;

        &:active,
        &:hover {
            color: $info-hover-color;
        }
        &:disabled {
            color: $info-disabled-color;
        }
    }

    .badge {
        border-radius: 0;
        margin: 1px 0;
        font-size: 9px;
        font-weight: normal;
    }
}

.alert {
    border-radius: 0;
    border: none;
    text-align: center;
    min-height: 22px;
    width: 100%;

    &-primary {
        background-color: $primary-color;
        color: white;
    }
    &-secondary {
        background-color: $secondary-color;
        color: white;
    }
    &-danger {
        background-color: $danger-color;
        color: white;
    }
    &-warning {
        background-color: $warning-color;
        color: white;
    }
    &-success {
        background-color: $success-color;
        color: white;
    }

    .close {
        padding: 0 15px;
        color: white;
        font-weight: normal;
        height: 100%;
        opacity: 1;
        text-shadow: none;
    }
}

.full-width {
    width: 100%;
    max-width: unset !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.page-loader {
    .main {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .loading {
            justify-content: center;
        }
    }
}

.align-left,
.align-left-xs {
    @media (min-width: $grid-breakpoints-xs) {
        text-align: left;
    }
}
.align-right,
.align-right-xs {
    @media (min-width: $grid-breakpoints-xs) {
        text-align: right;
    }
}
.align-center,
.align-center-xs {
    @media (min-width: $grid-breakpoints-xs) {
        text-align: center;
    }
}
.align-right-md {
    @media (min-width: $grid-breakpoints-md) {
        text-align: right;
    }
}
.align-center-md {
    @media (min-width: $grid-breakpoints-md) {
        text-align: center;
    }
}

.modal {
    backdrop-filter: blur(17px) brightness(1.5);

    .modal-dialog {
        max-width: 100%;
        margin: 0;

        @media (min-width: $grid-breakpoints-md) {
            max-width: 60%;
            margin: 28px auto;
        }

        .modal-content {
            border-radius: 0;
            border: none;
            padding: 0;
            @media (min-width: $grid-breakpoints-md) {
                padding: 36px;
            }

            .modal-header {
                width: 100%;
                border: none;
                align-self: center;
                padding: 15px;

                .modal-title.h4 {
                    width: 100%;
                    text-align: center;
                    font-size: 46px;
                    font-weight: normal;
                    word-break: break-word;
                    hyphens: auto;
                }
            }
            .modal-body {
                width: 100%;
                padding: 15px;
                border: none;
                align-self: center;

                ul.list-none {
                    list-style: none;
                    padding-inline-start: 0;
                }
            }
            .modal-footer {
                width: 100%;
                @media (min-width: $grid-breakpoints-md) {
                    width: initial;
                }
                padding: 15px;
                border: none;
                align-self: center;
                justify-content: center;

                & > * {
                    margin: 0;
                }

                .btn {
                    width: 100%;
                    @media (min-width: $grid-breakpoints-md) {
                        width: initial;
                    }

                    padding-left: 30px;
                    padding-right: 30px;
                    font-size: 20px;
                    border-radius: 0;
                    text-transform: uppercase;
                    margin-top: 10px;
                }

                .btn + .btn {
                    @media (min-width: $grid-breakpoints-md) {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    &.modal-wide {
        .modal-dialog {
            max-width: 100%;

            @media (min-width: $grid-breakpoints-md) {
                margin: 1.75rem;
                // max-width: 1334px;
            }
        }
    }
}

.invalid-feedback {
    font-size: 12px;
    margin-top: 4px;
}
