$grid-breakpoints-xs: 0; // Extra small screen / phone
$grid-breakpoints-sm: 575px; // Small screen / phone
$grid-breakpoints-md: 768px; // Medium screen / tablet
$grid-breakpoints-lg: 992px; // Large screen / desktop
$grid-breakpoints-xl: 1200px; // Extra large screen / wide desktop
$grid-breakpoints-xxl: 1366px; // Extra large screen / wide desktop

$grid-max-width: 1334px;

$navbar-height: 83px;
