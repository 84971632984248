@import "../styles/colors";
@import "../styles/grid";

.footer-container {
    color: $footer-text-color;
    background-color: $footer-background-color;
    padding-top: 78px;
    padding-bottom: 92px;

    .logo {
        margin-bottom: 52px;
        text-align: center;

        svg {
            width: 241px;
        }
    }

    .content {
        margin-top: 52px;
        margin-bottom: 52px;
        background-color: $footer-content-background-color;
        padding: 16px;

        .content-menu {
            text-align: center;
            max-width: $grid-max-width;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            @media (min-width: $grid-breakpoints-md) {
                flex-direction: row;
            }

            a {
                text-transform: uppercase;
                font-weight: 300;
                flex: 1 1 auto;
                margin: 10px 25px;
                color: white;
                text-decoration: none;
                white-space: nowrap;

                &:hover {
                    font-weight: 500;
                }
            }
            .ot-sdk-show-settings {
                border: none !important;
                padding: 0 !important;
                text-transform: uppercase;
                font-size: 14px !important;
                font-weight: 300;
                flex: 1 1 auto;
                margin: 10px 25px;
                color: white !important;
                background: none !important;
                text-decoration: none;
                white-space: nowrap;

                &:hover {
                    background: none !important;
                    font-weight: 500;
                }
            }
        }
    }

    .social {
        margin-top: 52px;
        margin-bottom: 52px;

        .col {
            display: flex;
            flex-direction: row;
            text-align: center;
            justify-content: center;
            color: white;
            width: 100%;

            .social-icon {
                margin: 0 15px;
                @media (min-width: $grid-breakpoints-sm) {
                    margin: 0 12px;
                }
                width: 32px;
                height: 32px;
                border: 1px solid white;

                &:hover {
                    color: $gray-light-color;
                    border: 1px solid $gray-light-color;
                }

                a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: white;

                    svg {
                        margin: 0 auto;
                        display: block;
                    }

                    &:hover {
                        color: $gray-light-color;
                    }
                }
            }
        }
    }

    .disclaimer {
        max-width: $grid-max-width;
        margin: 0 auto;

        .col {
            text-align: center;
            color: white;

            p {
                font-size: 12px;
            }
        }
    }
}
