@import "styles/fonts";
@import "styles/colors";
@import "styles/grid";

* {
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-weight: normal;
    color: $main-text-color;
    background-color: $main-background-color;
    // background-image: url("assets/background-hex.png");
    background-repeat: repeat;
    margin: 0;
    padding: 0;
}

.layout {
    display: flex;
    min-height: 100vh;
}
