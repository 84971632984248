@import "../../styles/colors";
@import "../../styles/grid";

.header {
    .cart {
        &.navbar-nav {
            svg {
                margin-right: 5px;
            }

            .fa-layers-counter {
                display: none;
                position: absolute;
                text-align: center;
                background-color: $danger-color;
                border-radius: 1em;
                box-sizing: border-box;
                color: #fff;
                height: 1.5em;
                line-height: 1;
                max-width: 5em;
                min-width: 1.5em;
                overflow: hidden;
                padding: 0.25em;
                right: 0;
                text-overflow: ellipsis;
                top: 0;
                transform: scale(0.25);
                transform-origin: top right;

                @media (min-width: $grid-breakpoints-lg) {
                    display: block;
                    transform: scale(0.9);
                    right: 55px;
                    top: 25px;
                }

                @media (min-width: $grid-breakpoints-xl) {
                    transform: scale(1);
                    right: 62px;
                    top: 20px;
                }
            }
        }
    }
}
