@import "./colors";
@import "./grid";

table {
    .align-left {
        text-align: left;
    }
    .align-right {
        text-align: right;
    }
    .align-center {
        text-align: center;
    }
    .align-justify {
        text-align: justify;
    }

    thead {
        .unsorted {
            color: $gray-color;
        }

        th {
            a {
                color: black;
                text-decoration: none;
            }
        }
    }

    tbody {
        &.table-loading {
            background-color: $gray-light-color;
        }
    }

    tfoot {
        background-color: $gray-light-color;
        td {
            font-weight: bold;
        }
    }
}

@media screen and (max-width: $grid-breakpoints-lg) {
    table.responsive {
        border: 0;

        caption {
            font-size: 1.3em;
        }

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border-bottom: 1px solid $gray-light-color;
            display: block;
            margin-bottom: 1rem;
            // border-left: 5px solid $primary-color;
        }

        td {
            border-top: none !important;
            border-bottom: none;
            display: block;
            font-size: 14px;
            line-height: 1.5;
            text-align: left;
            // min-height: 29px;
            padding: 0 0 12px 0 !important;

            a.btn {
                padding: 0.25rem 0.5rem;
                font-size: 0.875rem;
                line-height: 1.5;
                border-radius: 0.2rem;
            }
        }

        td::before {
            /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            // text-transform: uppercase;
            margin-right: 16px;
        }

        td:last-child {
            border-bottom: 0;
        }
    }
}
